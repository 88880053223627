import { Link } from "gatsby";
import React from "react";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";

import styles from "./header.module.css";

const Header = ({
  onHideNav,
  onShowNav,
  showNav,
  siteTitle,
  siteLogo,
  headerNavigation,
}) => (
  <header id="masthead" className="site-header wp1220" role="banner">
    <div id="logo">
      {siteLogo && (
        <a href="/" id="logoInside">
          <img
            src={imageUrlFor(buildImageObj(siteLogo)).url()}
            alt={siteLogo.alt}
          />
        </a>
      )}
    </div>
    <div className="navigation-top site-navigation-fixed">
      <div className="wrap">
        <nav
          id="site-navigation"
          className={showNav ? "main-navigation toggled-on" : "main-navigation"}
          role="navigation"
          aria-label="Top Menu"
        >
          <button
            className="menu-toggle"
            onClick={showNav ? onHideNav : onShowNav}
            aria-controls="top-menu"
            aria-expanded={showNav ? true : false}
          >
            <svg className="icon icon-bars" aria-hidden="true" role="img">
              {" "}
              <use href="#icon-bars" xlinkHref="#icon-bars" />{" "}
              <svg id="icon-bars" viewBox="0 0 27 32">
                <path
                  className="path1"
                  d="M27.429 24v2.286q0 0.464-0.339 0.804t-0.804 0.339h-25.143q-0.464 0-0.804-0.339t-0.339-0.804v-2.286q0-0.464 0.339-0.804t0.804-0.339h25.143q0.464 0 0.804 0.339t0.339 0.804zM27.429 14.857v2.286q0 0.464-0.339 0.804t-0.804 0.339h-25.143q-0.464 0-0.804-0.339t-0.339-0.804v-2.286q0-0.464 0.339-0.804t0.804-0.339h25.143q0.464 0 0.804 0.339t0.339 0.804zM27.429 5.714v2.286q0 0.464-0.339 0.804t-0.804 0.339h-25.143q-0.464 0-0.804-0.339t-0.339-0.804v-2.286q0-0.464 0.339-0.804t0.804-0.339h25.143q0.464 0 0.804 0.339t0.339 0.804z"
                />
              </svg>
            </svg>
            <svg className="icon icon-close" aria-hidden="true" role="img">
              {" "}
              <use href="#icon-close" xlinkHref="#icon-close" />{" "}
              <svg id="icon-close" viewBox="0 0 25 32">
                <path
                  className="path1"
                  d="M23.179 23.607q0 0.714-0.5 1.214l-2.429 2.429q-0.5 0.5-1.214 0.5t-1.214-0.5l-5.25-5.25-5.25 5.25q-0.5 0.5-1.214 0.5t-1.214-0.5l-2.429-2.429q-0.5-0.5-0.5-1.214t0.5-1.214l5.25-5.25-5.25-5.25q-0.5-0.5-0.5-1.214t0.5-1.214l2.429-2.429q0.5-0.5 1.214-0.5t1.214 0.5l5.25 5.25 5.25-5.25q0.5-0.5 1.214-0.5t1.214 0.5l2.429 2.429q0.5 0.5 0.5 1.214t-0.5 1.214l-5.25 5.25 5.25 5.25q0.5 0.5 0.5 1.214z"
                />
              </svg>
            </svg>
            Menu{" "}
          </button>
          <div className="menu-menu-container">
            <ul id="top-menu" className="menu">
              {headerNavigation &&
                headerNavigation.map((headerNav) => (
                  <li key={headerNav.target}>
                    <Link
                      activeClassName={styles.navActiveLink}
                      to={headerNav.target}
                    >
                      {headerNav.label}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </header>
);

export default Header;
